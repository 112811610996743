import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import { useAnalyticsService } from '../Intake/hooks/useAnalyticsService';
import ActivateForm from './ActivateForm';
import ErrorMessage from './components/ErrorMessage';
import { useGetUserInfoQuery } from './hooks/useGetUserInfoQuery';
import { useUpdateUserMutation } from './hooks/useUpdateUserMutation';
import PaymentRedirect from './PaymentRedirect';

const ActivatePage = () => {
	const navigate = useNavigate();
	const analytics = useAnalyticsService();
	const { token } = useParams<{ token: string }>();
	const { data, error, loading } = useGetUserInfoQuery({
		variables: {
			token,
		},
	});
	const [updateUser, { loading: submitting, error: updateError }] =
		useUpdateUserMutation();
	// Temporarily removed setShowPayment.
	const [showPayment] = useState<boolean>(false);

	const [password, setPassword] = useState<string>('');
	const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [passwordAlreadySet, setPasswordAlreadySet] =
		useState<boolean>(false);

	const setClickedLink = async () => {
		if (!data?.invite.id) return;
		await updateUser({
			variables: {
				id: data.invite.id,
				input: {
					clickedActivationLink: true,
				},
			},
			context: {
				headers: {
					Authorization: `Bearer ${data?.invite.accessToken}`,
				},
			},
		});
	};

	// If user has not been registered as clicking on the link in email, register here
	useEffect(() => {
		if (data?.invite.id && !data?.invite.clickedActivationLink) {
			setClickedLink();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		const sendEvent = async () => {
			await analytics.logScreenView({
				screen_name: 'password creation',
			});
		};

		sendEvent();
	}, [analytics]);

	/**
	 * Set a user's password when form is submitted
	 */
	const handleSubmit = async () => {
		if (!termsAgreed)
			return setErrorMessage('You must agree to the terms of service');
		if (!password || password.length < 8)
			return setErrorMessage(
				'Your password must be at least 8 characters long',
			);

		setErrorMessage(undefined);
		setPasswordAlreadySet(false);

		try {
			await updateUser({
				variables: {
					id: data?.invite.id ?? '',
					input: {
						password,
						currentPassword: password,
						termsAgreed,
					},
				},
				context: {
					headers: {
						Authorization: `Bearer ${data?.invite.accessToken}`,
					},
				},
			});
		} catch (err) {
			if (
				updateError &&
				updateError.graphQLErrors[0].extensions?.code === 'BAD_PASSWORD'
			) {
				setPasswordAlreadySet(true);
			} else {
				setErrorMessage(
					updateError?.graphQLErrors[0].message ??
						'An unknown error occured',
				);
			}
		} finally {
			if (!passwordAlreadySet && !updateError)
				navigate('/billing/success');
			// TEMPORARILY REMOVED. SET THIS BACK TO TAKE THE USER TO PAYMENT.
			// if (!passwordAlreadySet && !updateError) setShowPayment(true);
		}
	};

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black">
			<Container icon={showPayment ? 'sofa' : 'book'}>
				{error ? (
					<ErrorMessage error={error} />
				) : loading || !data ? (
					<h3 className="text-xl">
						We&apos;re just fetching your information, one sec...
					</h3>
				) : (
					<>
						{showPayment ? (
							<PaymentRedirect
								allowFreeTrial={
									!data.invite.hasUsedFreeTrial ?? false
								}
								showExplainer
								name={data.invite.firstName}
								accessToken={data.invite.accessToken}
							/>
						) : (
							<ActivateForm
								name={data.invite.firstName}
								email={data.invite.email}
								password={password}
								termsAgreed={termsAgreed}
								setTermsAgreed={setTermsAgreed}
								setPassword={setPassword}
								submitting={submitting}
								errorMessage={errorMessage}
								onSubmit={handleSubmit}
								passwordAlreadySet={passwordAlreadySet}
							/>
						)}
					</>
				)}
			</Container>
		</main>
	);
};

export default ActivatePage;
