/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import uplevyl1 from '../../assets/icons/Uplevyl1.svg';

const ConfirmationPage = () => {
	const params = useParams();
	const [showCopied, setShowCopied] = useState<boolean>(false);

	const newLink = `https://${window.location.hostname}#invitee=${params.username}`;

	const handleClick = () => {
		setShowCopied(true);
		navigator.clipboard.writeText(newLink);
		setTimeout(() => {
			setShowCopied(false);
		}, 1000);
	};

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black bg-floral-size bg-white bg-center">
			<div className="w-full lg:px-96 lg:py-64 flex flex-col justify-center text-center items-center px-10 py-16">
				<img
					alt="Uplevyl"
					className="w-56 mb-8"
					src={uplevyl1}
				/>
				<h1 className="mb-5 text-lg font-bold">
					Thank You!
				</h1>
				<h2 className="mb-5">
					Your application for Future Forward has been submitted and will now be reviewed.
					You will receive an email regarding your application status within 5-7 business days.

					<br /><br />
					If approved, you will receive an email with detailed instructions with payment details and for logging in and setting up your account so you can start get started in the community right away.

					<br /><br />
					If you would like to review the Future Forward program summary again, please <a href="https://www.uplevyl.com/future-forward" className="text-black underline">visit this page</a>.
					In the meantime, you might like to start Uplevyling right now by listening in to an episode or two from the Uplevyl podcast, <a href="https://go.uplevyl.com/podcast" className="text-black underline">Women. Wisdom. Worth.</a>
					<br /><br />
					Thanks again.
					<br />
					Uplevyl
				</h2>

				<div className="flex flex-wrap flex-row justify-center">
					<div
						className="border-black border rounded py-2 px-5 break-all cursor-pointer mb-5 text-sm mx-2"
						onClick={handleClick}
					>
						{newLink}
					</div>
					<button
						className="text-white py-2 bg-black rounded px-5 cursor-pointer mb-5 mx-2"
						onClick={handleClick}
					>
						{showCopied ? 'Copied!' : 'Copy'}
					</button>
				</div>
			</div>
		</main>
	);
};

export default ConfirmationPage;
