import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import {
	FilterList,
	FilterListItem,
	FilterLiveSearch,
	SavedQueriesList,
	useListContext,
} from 'react-admin';
import ActiveIcon from '@mui/icons-material/AppBlocking';

interface IUsersFilterSidebar {
	setFilter: React.Dispatch<any>;
}

const UsersFilterSidebar = ({ setFilter }: IUsersFilterSidebar) => {
	const { filterValues } = useListContext();
	setFilter(filterValues);

	return (
		<Card sx={{ order: -1, mr: 2, mt: 8, width: 250 }}>
			<CardContent>
				<SavedQueriesList />
				<FilterLiveSearch />
				<FilterList label="Active" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ active: true }} />
					<FilterListItem label="No" value={{ active: false }} />
				</FilterList>
				<FilterList label="Referred" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ referred: true }} />
					<FilterListItem label="No" value={{ referred: false }} />
				</FilterList>
				<FilterList label="Archived" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ archive: true }} />
					<FilterListItem label="No" value={{ archive: false }} />
				</FilterList>
				<FilterList label="Has Logged In" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ firstLogin: true }} />
					<FilterListItem label="No" value={{ firstLogin: false }} />
				</FilterList>
				<FilterList label="Has Been Active" icon={<ActiveIcon />}>
					<FilterListItem
						label="Yes"
						value={{ lastActivity: true }}
					/>
					<FilterListItem
						label="No"
						value={{ lastActivity: false }}
					/>
				</FilterList>
				<FilterList label="Enterprise" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ enterprise: true }} />
					<FilterListItem label="No" value={{ enterprise: false }} />
				</FilterList>
				<FilterList label="Has Set Milestones" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ milestones: true }} />
					<FilterListItem label="No" value={{ milestones: false }} />
				</FilterList>
				<FilterList label="Has A Comment" icon={<ActiveIcon />}>
					<FilterListItem label="Yes" value={{ hasComment: true }} />
					<FilterListItem label="No" value={{ hasComment: false }} />
				</FilterList>
				<FilterList label="Mobile OS" icon={<ActiveIcon />}>
					<FilterListItem
						label="Apple"
						value={{ mobileOS: 'APPLE' }}
					/>
					<FilterListItem
						label="Android"
						value={{ mobileOS: 'ANDROID' }}
					/>
					<FilterListItem
						label="Other"
						value={{ mobileOS: 'OTHER' }}
					/>
				</FilterList>
				<FilterList label="Activation" icon={<ActiveIcon />}>
					<FilterListItem
						label="Never clicked link"
						value={{ clickedActivationLink: 'null' }}
					/>
					<FilterListItem
						label="Clicked link, now active"
						value={{ clickedActivationLink: false }}
					/>
					<FilterListItem
						label="Clicked link, not yet active"
						value={{ clickedActivationLink: true }}
					/>
				</FilterList>
				<FilterList label="User Role" icon={<ActiveIcon />}>
					<FilterListItem label="Admin" value={{ role: 'ADMIN' }} />
					<FilterListItem label="Staff" value={{ role: 'STAFF' }} />
					<FilterListItem
						label="Manager"
						value={{ role: 'MANAGER' }}
					/>
					<FilterListItem
						label="Customer"
						value={{ role: 'CUSTOMER' }}
					/>
				</FilterList>
				{/* <FilterList label="Category" icon={<CategoryIcon />}>
                <FilterListItem label="Tests" value={{ category: 'tests' }} />
                <FilterListItem label="News" value={{ category: 'news' }} />
                <FilterListItem label="Deals" value={{ category: 'deals' }} />
                <FilterListItem label="Tutorials" value={{ category: 'tutorials' }} />
            </FilterList> */}
			</CardContent>
		</Card>
	);
};

export default UsersFilterSidebar;
