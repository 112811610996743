import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Input from '../../components/Input';

interface IActivateFormProps {
	name: string;
	email: string;
	password?: string;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
	setTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>;
	termsAgreed: boolean;
	submitting?: boolean;
	errorMessage?: string | undefined;
	passwordAlreadySet?: boolean;
	onSubmit: () => Promise<any>;
}
const ActivateForm = ({
	name,
	email,
	password,
	setPassword,
	termsAgreed,
	setTermsAgreed,
	submitting,
	errorMessage,
	onSubmit,
	passwordAlreadySet,
}: IActivateFormProps) => {
	const navigate = useNavigate();
	return (
		<>
			<Header title={`Welcome, ${name}`} />

			<p className="font-light mb-5">
				You now have access to the Uplevyl network!
			</p>
			<p className="font-light">
				Pick a secure password for your account to get started.
			</p>
			<form
				className="flex flex-col w-full mt-10"
				onSubmit={(e) => {
					e.preventDefault();
					return onSubmit();
				}}
			>
				<Input
					label="Email"
					type="email"
					className="mb-4 opacity-75"
					value={email}
					placeholder="Email"
					disabled
				/>
				<Input
					label="Password"
					type="password"
					placeholder="Pick a password"
					className="mb-4"
					disabled={submitting ?? false}
					required
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>

				<label
					className="inline-flex items-center my-2"
					htmlFor="terms"
					onChange={() => {
						setTermsAgreed(!termsAgreed);
					}}
				>
					<input
						type="checkbox"
						name="terms"
						className="form-checkbox h-5 w-5 text-gray-600 rounded"
						style={{
							backgroundColor: !termsAgreed
								? 'transparent'
								: '#333333',
						}}
						checked={termsAgreed}
					/>
					<span className="ml-2 text-gray-700 text-sm">
						I agree to the{' '}
						<a
							href="https://www.uplevyl.com/terms"
							className="text-black underline"
							target="_blank"
							rel="noreferrer"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							terms of service
						</a>
					</span>
				</label>
				<p className="text-sm text-red-400 mb-4 mt-2">{errorMessage}</p>

				{passwordAlreadySet && (
					<p className="text-sm text-center text-red-400 mb-4">
						It appears you have already set a different password. To
						manage your account, please{' '}
						<a
							onClick={(e) => {
								e.preventDefault();
								navigate('/login');
							}}
							href="/login"
							className="underline"
						>
							proceed to login
						</a>
						.
					</p>
				)}
				<Button
					type="submit"
					value="submit"
					disabled={!password || !termsAgreed || submitting}
				>
					{!submitting ? 'Get Started' : 'Submitting...'}
				</Button>
			</form>
		</>
	);
};

export default ActivateForm;
