/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '../../components/Button';
import { useGetUserCheckoutQuery } from './hooks/useGetUserCheckoutQuery';
import { UserCheckoutType } from './graphql/queries/getUserCheckout';
import Header from '../../components/Header';

interface PaymentRedirectProps {
	name: string;
	accessToken: string;
	showExplainer?: boolean;
	allowFreeTrial?: boolean;
}
const PaymentRedirect = ({
	name,
	accessToken,
	showExplainer,
	allowFreeTrial,
}: PaymentRedirectProps) => {
	const stripe = useStripe();

	const [yearly, setYearly] = useState<boolean>(false);

	const [getCheckout, { data, loading }] = useGetUserCheckoutQuery();

	const handleSubmit = () => {
		getCheckout({
			variables: {
				type: yearly
					? UserCheckoutType.YEARLY
					: UserCheckoutType.MONTHLY,
			},
			context: {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
		});
	};

	useEffect(() => {
		if (!data?.paymentCreateCheckout.sessionID) return;
		stripe?.redirectToCheckout({
			sessionId: data?.paymentCreateCheckout.sessionID,
		});
	}, [data, stripe]);

	return (
		<>
			{showExplainer && (
				<>
					<Header title={`Welcome, ${name}`} />

					<p className="font-light mb-5">
						It’s now time to start your Uplevyl Membership.
					</p>
				</>
			)}
			{allowFreeTrial && (
				<p className="font-light mt-2 mb-5">
					Each one comes with a{' '}
					<span className="font-bold">
						free 90 day free trial, cancel anytime
					</span>
					.
				</p>
			)}
			<div className="flex flex-row mb-5">
				<div
					className={`py-8 px-5 md:px-10 bg-oldYellow rounded-lg mr-3 cursor-pointer border-2 w-1/2 flex ${
						!yearly
							? ' border-oldYellowHighlight'
							: 'border-yellowed'
					}`}
					onClick={() => setYearly(false)}
				>
					<div className="flex flex-row justify-center items-end w-full">
						<h2 className="font-bold text-2xl md:text-3xl font-button">
							$9.99
						</h2>
						<p className="font-light">/month</p>
					</div>
				</div>
				<div
					className={`py-8 px-5 md:px-10 bg-oldYellow rounded-lg cursor-pointer border-2 w-1/2 flex ${
						yearly
							? ' border-oldYellowHighlight'
							: 'border-yellowed'
					}`}
					onClick={() => setYearly(true)}
				>
					<div className="flex flex-row justify-center w-full items-end">
						<h2 className="font-bold text-2xl md:text-3xl font-button">
							$110
						</h2>
						<p className="font-light">/year</p>
					</div>
				</div>
			</div>

			<Button
				type="submit"
				value="submit"
				disabled={loading}
				onClick={handleSubmit}
			>
				{!loading ? 'Continue' : 'Loading...'}
			</Button>
		</>
	);
};

export default PaymentRedirect;
