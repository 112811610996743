/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import Container from '../../components/Container';
import DownloadButton from '../../components/DownloadButton';
import Header from '../../components/Header';
import { useAnalyticsService } from '../Intake/hooks/useAnalyticsService';

const BillingSuccess = () => {
	const analytics = useAnalyticsService();
	useEffect(() => {
		const sendEvent = async () => {
			await analytics?.logScreenView({
				screen_name: 'membership confirmed',
			});
		};
		sendEvent();
	}, [analytics]);

	return (
		<Container icon="jumping">
			<Header title="Membership Confirmed" smallerText />

			<p className="font-light my-5">
				Congrats! You now have full access to personalized content and
				connections just for you!
			</p>
			<p className="font-light mb-5">
				To gain access, please download the app to proceed further.
			</p>
			<DownloadButton />
		</Container>
	);
};

export default BillingSuccess;
