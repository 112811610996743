import * as React from 'react';
import {
	Button,
	useRefresh,
	useNotify,
	useUnselectAll,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import ActivateIcon from '@mui/icons-material/VerifiedUser';
import { useEffect, useState } from 'react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Confirm from '../../../components/Confirm';

interface IActivateButtonProps {
	selectedIds: string[];
	resource?: string;
	single?: boolean;
}

const ActivateButton = ({
	selectedIds,
	resource = 'users',
	single,
}: IActivateButtonProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [isApprove, setIsApprove] = useState<any>(false);

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll('shares');
	const dataProvider = useDataProvider();

	const {
		mutate: activateUsers,
		isLoading,
		data,
		error,
	} = useMutation<{
		data: string[];
	}>(() => dataProvider.activate({ input: { ids: selectedIds } }));

	const {
		mutate: AdminUsersStatusUpdate,
		isLoading: isLoadingStatus,
		data: statusData,
		error: statusError,
	} = useMutation<{
		data: string[];
	}>(() => dataProvider.approveORReject({ input: { ids: selectedIds, "status" :isApprove === 'approve' ? "APPROVED" : "REJECTED" } }));

	const handleClick = () => setOpen(true);
	const handleIsActiveClick = (type) => setIsApprove(type);
	const handleIsActiveDialogClose = () => setIsApprove(false);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = () => {
		activateUsers();
		setOpen(false);
	};

	const handleRejectOrApproveConfirm = () => {
		console.log(isApprove)
		AdminUsersStatusUpdate();
		setIsApprove(false);
	};

	useEffect(() => {
		if (data?.data) {
			refresh();
			notify(`${data?.data.length} users have been activated`);
			unselectAll();
		} else if (data?.data.length === 0) {
			notify(
				'No users were activated. Make sure you select a user from the waitlist.',
			);
			unselectAll();
		} else if (error) {
			notify(`${error}`, { type: 'warning' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error]);

	useEffect(() => {
		if (statusData?.data) {
			const record: any = statusData?.data[0];
			refresh();
			notify(`${statusData?.data.length} users have been ${record?.status}`);
			unselectAll();
		} else if (statusData?.data.length === 0) {
			notify(
				'No users were activated. Make sure you select a user from the waitlist.',
			);
			unselectAll();
		} else if (statusError) {
			notify(`${statusError}`, { type: 'warning' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusData, statusError]);

	return (
		<>
		<Button
				color='success'
				label="Approve"
				disabled={isLoadingStatus}
				onClick={() => handleIsActiveClick('approve')}
			>
				<HowToRegIcon />
			</Button>
			<Button
				color='warning'
				label="Reject"
				disabled={isLoadingStatus}
				onClick={() => handleIsActiveClick('reject')}
			>
				<NoAccountsIcon />
			</Button>
			<Button
				label={`Activate user${single ? '' : 's'}`}
				disabled={isLoading}
				onClick={handleClick}
			>
				<ActivateIcon />
			</Button>
			<Confirm
				isOpen={open}
				loading={isLoading}
				title={`Activate User${single ? '' : 's'}`}
				content={`Are you sure you want to activate ${selectedIds.length} user accounts? This will remove them from the waitlist and allow them to purchase a membership.`}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
			<Confirm
				isOpen={isApprove}
				loading={isLoadingStatus}
				title={`${isApprove} User${single ? '' : 's'}`}
				content={`Are you sure you want to ${isApprove} ${selectedIds.length} user accounts?`}
				onConfirm={handleRejectOrApproveConfirm}
				onClose={handleIsActiveDialogClose}
			/>
		</>
	);
};

export default ActivateButton;
